import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import iconstopwatch from "../images/icon-stopwatch.svg";
import iconlightbulb from "../images/icon-light-bulb.svg";
import icondata from "../images/icon-data.svg";
import iconroadmap from "../images/icon-roadmap.svg";
import heroimage from "../images/digital-media-audit-hero.jpg";
import audithelpdevice from "../images/digital-media-audit-help-device.png";
import audithelpscorecard from "../images/digital-media-audit-help-scorecard.png";
import "../scss/digitalMediaAudit.scss";
import "../scss/auditHeroSection.scss";

const AuditDigitalMedia = ({ data }) => {
  const metadescription = "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success.";
  const metatitle = "Digital Media Audit - Next&Co";
  const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <div id="digital-media-audit">
        <Layout>
          <section id="row-one">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h1>Digital Media Audit</h1>
                  </div>
                    <div className="col-two"
			 style={{
			     display: `flex`,
			     alignItems: `center`,
			 }}>
			<p>
			    Our proprietary digital media audit tool called <Link to="../prometheus/">Prometheus</Link> is a holistic examination of your brands digital media effectiveness.
			</p>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="audit-hero-section"
            style={{
              backgroundImage: `url(${heroimage})`,
              backgroundPosition: `center center`,
              backgroundAttachment: `fixed`,
            }}
          >
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <div className="list-item">
                      <div className="icon">
                        <img src={iconstopwatch} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Locate waste spend
                        </h2>
                        <p>
                          Assess your brands media and find spend that is not attributed to a conversion
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={icondata} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Media tech
                        </h2>
                        <p>
                          Assess your brands use of adtech
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={iconlightbulb} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Find scale
                        </h2>
                        <p>
                          Find opportunities to scale media spend (eg. audiences, channels, campaigns etc)
                        </p>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <img src={iconroadmap} alt="" />
                      </div>
                      <div className="content">
                        <h2>
                          Build roadmap
                        </h2>
                        <p>
                          Define both an immediate and long-term roadmap to optimise performance and channel strategy
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-two">
                    <div className="form-container">
                      <h2>Enquire about a digital media audit for your brand</h2>
                      {/* <h1>{this.props.formSubmitUrl}</h1> */}
                      <form
                        name="dm-audit"
                        method="post"
                        action="/dma-thankyou/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                      >
                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                        <input type="hidden" name="form-name" value="dm-audit" />
                        <p hidden>
                          <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field"/>
                          </label>
                        </p>
                        <p></p>
                        <p>
                          <label>
                            Your Name
                            <input
                              type="text"
                              required
                              name="your-name"
                              placeholder="Your name"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            URL
                            <input
                              type="text"
                              required
                              name="website-url"
                              placeholder="Website URL"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            Email
                            <input
                              type="email"
                              required
                              name="email"
                              placeholder="Your email"
                            />
                          </label>
                        </p>
                        <p>
                          <label>
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              required
                              placeholder="Your phone"
                            />
                          </label>
                        </p>
                        <p>
                          <button className="btn btn-invert" type="submit">
                            Get your audit
                          </button>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="audit-help">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col">
                    <img src={audithelpdevice} alt="" />
                  </div>
                  <div className="col">
                    <img src={audithelpscorecard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    </>
  )
}

export default AuditDigitalMedia
